// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Layout from "../shared/Layout.re.mjs";
import * as WaitForMessages from "../shared/i18n/WaitForMessages.re.mjs";
import * as JsxRuntime from "react/jsx-runtime";

import { css, cx } from '@linaria/core'
;

import { t } from '@lingui/macro'
;

function Footer(props) {
  return JsxRuntime.jsx(WaitForMessages.make, {
              children: (function () {
                  return JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(Layout.Container.make, {
                                    children: JsxRuntime.jsx("div", {
                                          children: t`copyright the racquet league contributors`,
                                          className: "grid grid-cols-1 gap-4 text-xs leading-5"
                                        })
                                  }),
                              className: "border-t py-3 mt-3"
                            });
                })
            });
}

var make = Footer;

var $$default = Footer;

export {
  make ,
  $$default as default,
}
/*  Not a pure module */
